import cmslogo from "../assets/images/schoolIcons/cms-logo-072e96.webp";
import { Link } from "react-router-dom";
import { Navbar, Nav, Image, Dropdown, Button } from "react-bootstrap";
import Search from "../components/Search.js";
import datalist from "../DataList.json";

const DropdownMenu = ({ id, title, items }) => (
  <Dropdown>
    <Dropdown.Toggle className="nav-dropdown" id={id}>
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {items.map((item, index) => (
        <Dropdown.Item key={index} as={Link} to={item.to}>
          {item.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const NavButton = ({ to, label }) => (
  <Button as={Link} to={to} className="bgdis-item" aria-label={label}>
    {label}
  </Button>
);

export default function Header() {
  const schoolsMenuItems = [
    { to: "/schools", label: "All Schools" },
    { to: "/schools/chs/chshome", label: "Carlsbad High School" },
    {
      to: "/schools/cechs/cechshome",
      label: "Carlsbad Early College High School",
    },
    { to: "/schools/cec/cechome", label: "Carlsbad Enrichment Center" },
    { to: "/schools/cisaltavista/avhome", label: "CIS Alta Vista" },
    { to: "/schools/cisprleyva/prhome", label: "CIS P.R. Leyva" },
    { to: "/schools/cw/cwhome", label: "Cottonwood" },
    { to: "/schools/dw/dwhome", label: "Desert Willow" },
    { to: "/schools/mont/monthome", label: "Monterrey" },
    { to: "/schools/oco/ocohome", label: "Ocotillo" },
    { to: "/schools/riv/rivhome", label: "Riverside" },
    { to: "/schools/sun/sunhome", label: "Sunset" },
    { to: "/schools/ecec/ecechome", label: "Early Childhood Education Center" },
  ];

  const departmentsMenuItems = [
    { to: "/departments", label: "All Departments" },
    { to: "/departments/admin", label: "Administration" },
    { to: "/departments/athletics", label: "Athletics" },
    { to: "/departments/capitalProjects", label: "Capital Projects" },
    { to: "/departments/FederalPrograms", label: "Federal Programs" },
    { to: "/departments/finance", label: "Finance" },
    { to: "/departments/hr", label: "Human Resources" },
    { to: "/departments/nutrition", label: "Nutrition and Food Services" },
    { to: "/departments/operations", label: "Operations" },
    { to: "/departments/purchasing", label: "Purchasing" },
    { to: "/departments/sped", label: "Special Services" },
    { to: "/departments/learning", label: "Teaching and Learning" },
    { to: "/departments/technology", label: "Technology" },
  ];

  const programsMenuItems = [
    // { to: "/programs", label: "All Programs" },
    { to: "/programs/opportunityculture", label: "Opportunity Culture" },
    { to: "/programs/kidsreadnow", label: "Kids Read Now" },
    { to: "/programs/crisisresponse", label: "Crisis Response" },
    // { to: "/programs/parentsquare", label: "ParentSquare" },
  ];

  return (
    <Navbar expand="lg" className="top-nav">
      <div className="top-nav-logo">
        <Link to="/">
          <Image src={cmslogo} className="cms-logo" alt="CMSD logo" />
        </Link>
        <div className="top-nav-motto">
          <p>CARLSBAD MUNICIPAL SCHOOL DISTRICT</p>
          <p>Excellence Today, Opportunity Tomorrow</p>
        </div>
      </div>

      <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
      <Navbar.Collapse id="basic-navbar-nav" className="navbar-container">
        <Nav className="top-nav-align">
          <NavButton to="/" label="Home" />
          <NavButton to="/about" label="About" />
          <DropdownMenu
            id="dropdown-schools"
            title="Schools"
            items={schoolsMenuItems}
          />
          <DropdownMenu
            id="dropdown-departments"
            title="Departments"
            items={departmentsMenuItems}
          />
          <DropdownMenu
            id="dropdown-programs"
            title="Programs"
            items={programsMenuItems}
          />
          <NavButton to="/media" label="Media" />
          <NavButton to="/employment" label="Employment" />
        </Nav>
        <div className="searchbar">
          <Search placeholder="Search" data={datalist} />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}
