import { Container, Row } from "react-bootstrap";

export default function JosefinSans() {
  return (
    <Container fluid className="josefinsans-container">
      <main>
        <Row className="pg-main100vh text-center">
          <h1>Board Work Session</h1>
          <h1>Regular Board Meeting</h1>
          <h1>Special Board Meeting</h1>
          <h4>Board Work Session</h4>
          <h5>Board Work Session</h5>
        </Row>
      </main>
    </Container>
  );
}
