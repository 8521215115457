import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Container, Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import cmslogo from "../assets/images/schoolIcons/cms-logo-072e96.webp";

const footerLinks = [
  { to: "/about/staffquicklinks", text: "Staff Quick Links" },
  { to: "/employment", text: "Employment" },
  { to: "/about/calendar", text: "Calendar" },
  { href: "https://www.parentsquare.com/districts/1619", text: "News" }, // Use 'href' for external links
  { to: "/about/maps-directions", text: "Map & Directions" },
  { to: "/about/sitemap", text: "Site Map" },
  { to: "/about/staffdirectory", text: "Staff Directory" },
  {
    to: "/about/nondiscrimination",
    text: "Notice of Non-Discrimination",
  },
];

function Footer() {
  return (
    <Container fluid className="footer-container">
      <Row>
        <Col xs={12} sm={6} md={3} lg={1} className="footer-logo order-md-1">
          <Image src={cmslogo} className="cms-logo" alt="CMSD logo" />
        </Col>
        <Col xs={12} sm={6} md={9} lg={3} className="footer-address order-md-2">
          <h6>Carlsbad Municipal School District</h6>
          <address>
            <p>408 N. Canyon St.</p>
            <p>Carlsbad, NM 88220</p>
            <p>Phone: (575) 234-3300</p>
            <p>Fax: (575) 234-3367</p>
          </address>
        </Col>
        <Col xs={12} sm={6} md={12} lg={4} className="footer-links order-md-3">
          <Row>
            {footerLinks.map((link, index) => (
              <Col xs={12} sm={6} key={index}>
                {link.href ? (
                  <a
                    href={link.href}
                    className="d-block mb-2"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {link.text}
                  </a>
                ) : (
                  <Link to={link.to} className="d-block mb-2">
                    {link.text}
                  </Link>
                )}
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} className="footer-icons order-md-4">
          <ul className="icons list-unstyled d-flex justify-content-center">
            <li className="mr-2">
              <a
                href="https://twitter.com/carlsbadmsd"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Link to our Twitter"
                className="icon twitter"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/carlsbadmsd"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Link to our Facebook"
                className="icon facebook"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
